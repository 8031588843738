import { FC, useEffect } from "react";
import styles from "./ObserverList.module.css";
import { useData } from "../../providers/DataProvider";
import { ReactComponent as TrashIcon } from "../../shared/assets/trash.svg";
import { Drawer } from "../Drawer";

interface ObserverListProps {
  isActive: boolean;
  onClose: () => void;
}

export const ObserverList: FC<ObserverListProps> = (props) => {
  const { isActive, onClose } = props;
  const { observerList, isObserversLoading, fetchObservers, cancelObserve } = useData();

  useEffect(() => {
    if (isActive) {
      fetchObservers();
    }
  }, [isActive]);

  const handleCancelObserve = (id: number) => () => {
    cancelObserve(id);
  };

  return (
    <Drawer isActive={isActive} title={"Наблюдение за залами"} onClose={onClose}>
      {!isObserversLoading && !observerList.length && (
        <span className={styles.placeholder}>Нет активных подписок</span>
      )}
      {!isObserversLoading && (
        <ul className={styles.list}>
          {observerList.map((observer, i) => (
            <li key={observer.id} className={styles.item}>
              <p>{observer.hall}</p>
              <TrashIcon
                className={styles.icon}
                width={25}
                height={25}
                color={"#ec5656"}
                onClick={handleCancelObserve(observer.id)}
              />
            </li>
          ))}
        </ul>
      )}
    </Drawer>
  );
};
