import { ChangeEvent, FC, ReactNode } from "react";
import styles from "./Input.module.css";
import { ReactComponent as EditIcon } from "../../shared/assets/edit.svg";
import { useThemeParams } from "@vkruglikov/react-telegram-web-app";

interface InputProps {
  label: string;
  value?: string;
  placeholder?: string;
  icon: ReactNode;
  onChange: () => void;
}

export const Input: FC<InputProps> = (props) => {
  const { label, value, icon, placeholder, onChange } = props;
  const [colorScheme, themeParams] = useThemeParams();
  const iconColor = colorScheme === "dark" ? "var(--lightest-gray-color)" : "rgba(0, 0, 0, 0.4)";
  const saveValue = value ? (value?.length > 30 ? `${value?.slice(0, 30)}...` : value) : undefined;

  return (
    <div className={styles.wrapper} onClick={onChange}>
      <span className={styles.label}>{label}</span>
      <div className={styles.inputWrapper}>
        <div className={styles.icon}>{icon}</div>
        {!value && placeholder && <p className={styles.placeholder}>{placeholder}</p>}
        {value && <p className={styles.value}>{saveValue}</p>}
        <div className={styles.icon}>
          <EditIcon color={iconColor} />
        </div>
      </div>
    </div>
  );
};
