import axios from "axios";
import { FetchCurrentCaseData, FetchNextCasesData, FetchObserverData, RequestData } from "./types";

const apiClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEV_API_HOST
      : process.env.REACT_APP_API_HOST,
});

export const fetchData = (initial: boolean = false) => {
  return apiClient.get<RequestData>(`/api/data?${Telegram.WebApp.initData}&initial=${initial}`);
};

export const fetchCurrentCase = (courtTag: string, hall: string) => {
  return apiClient.post<FetchCurrentCaseData>(`/api/case/current?${Telegram.WebApp.initData}`, {
    data: {
      courtTag,
      hall,
    },
  });
};

export const fetchNextCases = (courtTag: string, hall: string) => {
  return apiClient.post<FetchNextCasesData>(`/api/case/next?${Telegram.WebApp.initData}`, {
    data: {
      courtTag,
      hall,
    },
  });
};

export const fetchObservers = () => {
  return apiClient.get<FetchObserverData>(`/api/user/observers?${Telegram.WebApp.initData}`);
};

interface ChangeUserSettingsParams {
  currentCourt?: string;
  currentHall?: string;
}

export const changeUserSettings = (params: ChangeUserSettingsParams) => {
  return apiClient.post(`/api/user/update?${Telegram.WebApp.initData}`, {
    data: params,
  });
};

export const initObserver = (courtTag: string, hallValue: string) => {
  return apiClient.post(`/api/user/observers/init?${Telegram.WebApp.initData}`, {
    data: {
      courtTag,
      hallValue,
    },
  });
};

export const cancelObserve = (id: number) => {
  return apiClient.post(`/api/user/observers/cancel?${Telegram.WebApp.initData}`, {
    data: {
      observerId: id,
    },
  });
};
