import { createContext, FC, ReactNode, useContext, useEffect, useState } from "react";
import { Case, Court, Hall, Observer } from "../shared/types";
import {
  cancelObserve,
  changeUserSettings,
  fetchCurrentCase,
  fetchData,
  fetchNextCases,
  fetchObservers,
  initObserver,
} from "../shared/api";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

interface DataProviderContextValue {
  isLoading: boolean;
  isAuth: boolean;
  isCaseLoading: boolean;
  isObserversLoading: boolean;
  courts: Court[];
  halls: Hall[];
  currentCourt: Court | null;
  currentHall: Hall | null;
  currentCase: Case | null;
  caseList: Case[];
  observerList: Observer[];
  updateHall: (hall: Hall) => void;
  updateCourt: (hall: Court) => void;
  fetchCurrentCase: () => void;
  fetchNextCases: () => void;
  fetchObservers: () => void;
  observe: (courtTag: string, hallValue: string) => void;
  cancelObserve: (id: number) => void;
}

const DataProviderContext = createContext<DataProviderContextValue>({
  isLoading: false,
  isAuth: false,
  isCaseLoading: false,
  isObserversLoading: false,
  courts: [],
  halls: [],
  currentCourt: null,
  currentHall: null,
  currentCase: null,
  caseList: [],
  observerList: [],
  updateHall: () => {},
  updateCourt: () => {},
  fetchCurrentCase: () => {},
  fetchNextCases: () => {},
  fetchObservers: () => {},
  observe: () => {},
  cancelObserve: () => {},
});

export const useData = () => useContext(DataProviderContext);

interface DataProviderProps {
  children: ReactNode;
}

export const DataProvider: FC<DataProviderProps> = (props) => {
  const { children } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isCaseLoading, setIsCaseLoading] = useState(false);
  const [isObserversLoading, setIsObserversLoading] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [courts, setCourts] = useState<Court[]>([]);
  const [halls, setHalls] = useState<Hall[]>([]);
  const [currentCourt, setCurrentCourt] = useState<Court | null>(null);
  const [currentHall, setCurrentHall] = useState<Hall | null>(null);
  const [currentCase, setCurrentCase] = useState<Case | null>(null);
  const [caseList, setCaseList] = useState<Case[]>([]);
  const [observerList, setObserverList] = useState<Observer[]>([]);
  const [impactOccurred, notification, selection] = useHapticFeedback();

  useEffect(() => {
    handleFetchData(true);
  }, []);

  const handleFetchData = (initial?: boolean) => {
    fetchData(initial)
      .then((response) => {
        const { courts, halls, user, currentCase } = response.data;
        const currentCourt = courts.find((court) => court.title === user.currentCourt) ?? null;
        const currentHall = halls.find((hall) => hall.title === user.currentHall) ?? null;
        if (currentCase) {
          setCurrentCase(currentCase);
        }
        setCourts(courts);
        setHalls(halls);
        setCurrentCourt(currentCourt);
        setCurrentHall(currentHall);
        setIsLoading(false);
        setIsAuth(true);
        handleFetchObservers();
      })
      .catch((e) => {
        setIsAuth(false);
        setIsLoading(false);
      });
  };

  const handleFetchObservers = () => {
    setIsObserversLoading(true);
    fetchObservers()
      .then((response) => {
        console.log("new observer list", response.data.data);
        setObserverList(response.data.data);
        setIsObserversLoading(false);
      })
      .catch(() => {
        setIsObserversLoading(false);
      });
  };

  const handleCourtChange = (court: Court) => {
    setCurrentCourt(court);
    changeUserSettings({
      currentCourt: court.title,
    }).then(() => {
      handleFetchData();
    });
  };

  const handleHallChange = (hall: Hall) => {
    setCurrentHall(hall);
    setCurrentCase(null);
    setCaseList([]);
    changeUserSettings({
      currentHall: hall.title,
    }).then(() => {
      handleFetchData();
    });
  };

  const handleFetchNextCases = () => {
    if (!currentCourt || !currentHall) return;
    setCaseList([]);
    setIsCaseLoading(true);
    fetchNextCases(currentCourt.value, currentHall.value)
      .then((response) => {
        setCaseList(response.data.data);
        setIsCaseLoading(false);
      })
      .catch(() => {
        setIsCaseLoading(false);
      });
  };

  const handleFetchCurrentCase = (courtTag?: string, hallValue?: string) => {
    const tag = courtTag ?? currentCourt?.value;
    const hall = hallValue ?? currentHall?.value;

    if (!tag || !hall) return;

    setCurrentCase(null);

    setIsCaseLoading(true);
    fetchCurrentCase(tag, hall)
      .then((response) => {
        if (response.data.data) {
          setCaseList([response.data.data]);
        }
        setIsCaseLoading(false);
      })
      .catch(() => {
        setIsCaseLoading(false);
      });
  };

  const handleObserve = (courtTag: string, hallValue: string) => {
    void initObserver(courtTag, hallValue)
      .then(() => {
        notification("success");
        handleFetchObservers();
      })
      .catch(() => {
        notification("error");
      });
  };

  const handleCancelObserve = (id: number) => {
    setObserverList((prev) => prev.filter((observer) => observer.id !== id));
    cancelObserve(id).then(() => {
      handleFetchObservers();
      impactOccurred("light");
    });
  };

  const value = {
    isLoading,
    isAuth,
    isCaseLoading,
    isObserversLoading,
    courts,
    halls,
    currentCourt,
    currentHall,
    currentCase,
    caseList,
    observerList,
    updateCourt: handleCourtChange,
    updateHall: handleHallChange,
    fetchCurrentCase: handleFetchCurrentCase,
    fetchNextCases: handleFetchNextCases,
    fetchObservers: handleFetchObservers,
    observe: handleObserve,
    cancelObserve: handleCancelObserve,
  };

  return <DataProviderContext.Provider value={value}>{children}</DataProviderContext.Provider>;
};
