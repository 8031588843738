import { FC, useEffect, useRef, useState } from "react";
import { register } from "swiper/element/bundle";
import styles from "./CaseTabs.module.css";
import { useData } from "../../providers/DataProvider";
import { CaseCard } from "../CaseCard";
import { useHapticFeedback, useThemeParams } from "@vkruglikov/react-telegram-web-app";
import { Tabs } from "./Tabs";

register();

interface CaseTabsProps {}

export const CaseTabs: FC<CaseTabsProps> = () => {
  const { currentCase, caseList, fetchCurrentCase, fetchNextCases, currentHall } = useData();
  const [colorScheme] = useThemeParams();
  const [, , selection] = useHapticFeedback();

  const [activeTab, setActiveTab] = useState(0);
  const swiperRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    selection();
  }, [activeTab]);

  useEffect(() => {
    fetchNextCases();
  }, [currentHall]);

  useEffect(() => {
    fetchNextCases();

    if (!swiperRef.current) return;

    const params = {
      initialSlide: activeTab,
      slidesPerView: 1,
      infinity: true,
      spaceBetween: 100,
    };

    Object.assign(swiperRef.current, params);

    // @ts-ignore
    swiperRef.current?.addEventListener("slidechange", (e: any) => {
      const activeIndex = e.detail[0].activeIndex;
      setActiveTab(activeIndex);
    });

    // @ts-ignore
    swiperRef.current.initialize();
  }, []);

  const handleTabClicked = (tabId: number) => {
    setActiveTab(tabId);
    if (tabId > activeTab) {
      // @ts-ignore
      swiperRef.current?.swiper.slideNext();
    } else {
      // @ts-ignore
      swiperRef.current?.swiper.slidePrev();
    }
  };

  return (
    <div className={styles.caseTabs}>
      <Tabs activeTab={activeTab} onTabClicked={handleTabClicked} />
      <swiper-container ref={swiperRef} style={{ height: "100%", flex: "1 0 auto" }}>
        <swiper-slide style={{ minHeight: 200 }}>
          {!currentCase && (
            <span
              className={[
                styles.placeholder,
                colorScheme === "dark" ? styles.placeholderDark : styles.placeholderLight,
              ].join(" ")}
            >
              В данный момент не рассматривается ни одно дело.
            </span>
          )}
          {currentCase && <CaseCard data={currentCase} />}
        </swiper-slide>
        <swiper-slide style={{ minHeight: 200 }}>
          {!caseList.length && (
            <span
              className={[
                styles.placeholder,
                colorScheme === "dark" ? styles.placeholderDark : styles.placeholderLight,
              ].join(" ")}
            >
              В выбранном зале нет запланированных заседаний.
            </span>
          )}
          <div className={styles.list}>
            {caseList.map((row) => (
              <CaseCard key={row.caseId} data={row} />
            ))}
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  );
};
