import { ChangeEvent, FC, useEffect, useRef } from "react";
import styles from "./Input.module.css";

interface InputProps {
  focus: boolean;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  onFocus: () => void;
}

export const Input: FC<InputProps> = (props) => {
  const { focus, value, error, onChange, onFocus } = props;
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (focus) {
      ref.current?.focus();
    }
  }, [focus]);

  useEffect(() => {
    // ref.current?.addEventListener("blur", onBlur);
    ref.current?.addEventListener("focus", onFocus);

    return () => {
      // ref.current?.removeEventListener("blur", onBlur);
      ref.current?.removeEventListener("focus", onFocus);
    };
  }, [onFocus]);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const classes = [styles.input];

  if (error) {
    classes.push(styles.error);
  }

  return <input ref={ref} className={classes.join(" ")} value={value} onChange={onChange} />;
};
