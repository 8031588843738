import { FC, ReactNode, useEffect, useState } from "react";
import styles from "./Drawer.module.css";
import { ReactComponent as ArrowLeftIcon } from "../../shared/assets/arrow-left.svg";
import { useThemeParams } from "@vkruglikov/react-telegram-web-app";

interface DrawerProps {
  children: ReactNode;
  isActive: boolean;
  title: string;
  onClose: () => void;
}

export const Drawer: FC<DrawerProps> = (props) => {
  const { isActive, title, onClose, children } = props;
  const [_, themeParams] = useThemeParams();
  const [isMounted, setIsMounted] = useState(false);
  const [additionalStyles, setAdditionalStyles] = useState([styles.left]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    if (isActive) {
      setAdditionalStyles([styles.openLeft]);
    } else {
      setAdditionalStyles([styles.closeLeft]);
    }
  }, [isActive]);

  const handleClose = () => {
    setAdditionalStyles([styles.closeLeft]);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
    <div className={[styles.wrapper, ...additionalStyles].join(" ")}>
      <div className={styles.header}>
        <span className={styles.closeButton} onClick={handleClose}>
          <ArrowLeftIcon width={18} height={18} color={themeParams.text_color} />
        </span>
        <h2 className={styles.title}>{title}</h2>
      </div>
      {children}
    </div>
  );
};
