import { useHapticFeedback, useThemeParams } from "@vkruglikov/react-telegram-web-app";
import styles from "./App.module.css";
import { Input } from "./components/Input";
import { Search } from "./components/Search";
import { SearchItem } from "./components/Search/Search";
import { LoadingScreen } from "./components/LoadingScreen";
import { ObserverList } from "./components/ObserverList";
import { Court, Hall } from "./shared/types";
import { useData } from "./providers/DataProvider";
import { useEffect, useState } from "react";
import { ReactComponent as CourtIcon } from "./shared/assets/court.svg";
import { ReactComponent as HallIcon } from "./shared/assets/hall.svg";
import { Switch } from "./components/Swich";
import { CaseTabs } from "./components/CaseTabs";
import { ReactComponent as ArrowRightIcon } from "./shared/assets/arrow-right.svg";

function App() {
  const {
    isLoading,
    isAuth,
    courts,
    halls,
    currentCourt,
    currentHall,
    updateCourt,
    updateHall,
    observe,
    cancelObserve,
    observerList,
  } = useData();
  const [colorScheme, themeParams] = useThemeParams();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isObserverListActive, setIsObserverListActive] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchItems, setSearchItems] = useState<SearchItem[]>([]);
  const [isCurrentHallObserved, setIsCurrentHallObserved] = useState(false);
  const [, , selection] = useHapticFeedback();

  useEffect(() => {
    if (observerList.find((observer) => observer.hall === currentHall?.value)) {
      setIsCurrentHallObserved(true);
    } else {
      setIsCurrentHallObserved(false);
    }
  }, [observerList, currentHall]);

  const handleSearchCourt = () => {
    setSearchTitle("Поиск суда");

    setSearchItems(
      courts.map((court) => ({
        title: court.title,
        value: court.value,
        type: "court",
      }))
    );

    selection();
    setIsSearchActive(true);
  };

  const handleSearchHall = () => {
    setSearchTitle("Поиск зала");

    setSearchItems(
      halls.map((hall) => ({
        title: hall.title,
        value: hall.value,
        type: "hall",
      }))
    );

    selection();
    setIsSearchActive(true);
  };

  const handleCloseSearch = () => {
    setIsSearchActive(false);
    setSearchTitle("");
    setSearchItems([]);
  };

  const handleSearchChange = (item: SearchItem) => {
    if (item.type === "hall") {
      updateHall(item as unknown as Hall);
    }
    if (item.type === "court") {
      updateCourt(item as unknown as Court);
    }
    handleCloseSearch();
  };

  const handleObserverListClicked = () => {
    selection();
    setIsObserverListActive(true);
  };

  const handleObserverListClose = () => {
    setIsObserverListActive(false);
  };

  const handleObserve = () => {
    if (!currentCourt || !currentHall) return;

    if (isCurrentHallObserved) {
      const observer = observerList.find((observer) => observer.hall === currentHall.value);
      if (observer) {
        cancelObserve(observer.id);
      }
    } else {
      observe(currentCourt.value, currentHall.value);
    }
  };

  if (isLoading) {
    return (
      <div className={styles.app}>
        <LoadingScreen />
      </div>
    );
  }

  if (!isAuth) {
    return (
      <div className={styles.app}>
        <p>Нет доступа.</p>
      </div>
    );
  }

  const isObserveButtonDisabled = isCurrentHallObserved ? false : !currentHall || observerList.length > 2;
  const iconColor = colorScheme === "dark" ? "var(--lightest-gray-color)" : "rgba(0, 0, 0, 0.4)";

  const menuItemStyles = [styles.menuItem];

  if (colorScheme === "light") {
    menuItemStyles.push(styles.menuItemLight);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.inputWrapper}>
          <Input
            label={"Суд"}
            placeholder={"Выберите суд"}
            value={currentCourt?.title}
            icon={<CourtIcon color={iconColor} />}
            onChange={handleSearchCourt}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            label={"Зал"}
            placeholder={"Выберите зал заседаний"}
            value={currentHall?.title}
            icon={<HallIcon color={iconColor} />}
            onChange={handleSearchHall}
          />
        </div>
        <div className={styles.menu}>
          <div className={menuItemStyles.join(" ")}>
            <p>Присылать уведомления об изменениях</p>
            <Switch
              disabled={isObserveButtonDisabled}
              checked={isCurrentHallObserved}
              onChange={handleObserve}
            />
          </div>
          <div
            style={{ cursor: "pointer" }}
            className={menuItemStyles.join(" ")}
            onClick={handleObserverListClicked}
          >
            <p>Мой мониторинг</p>
            <ArrowRightIcon className={styles.rowIcon} width={25} height={25} color={iconColor} />
          </div>
        </div>

        <CaseTabs />
      </div>
      <Search
        isActive={isSearchActive}
        title={searchTitle}
        items={searchItems}
        onChange={handleSearchChange}
        onClose={handleCloseSearch}
      />
      <ObserverList isActive={isObserverListActive} onClose={handleObserverListClose} />
    </div>
  );
}

export default App;
