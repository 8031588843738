import { FC } from "react";
import styles from "./LoadingScreen.module.css";
import { Skeleton } from "../Skeleton";

interface LoadingScreenProps {}

export const LoadingScreen: FC<LoadingScreenProps> = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton width={"100%"} height={"155px"} borderRadius={5} />
      <Skeleton width={"100%"} height={"130px"} borderRadius={6} />
      <Skeleton width={"100%"} height={"197px"} borderRadius={15} />
    </div>
  );
};
