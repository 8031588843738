import { FC } from "react";
import styles from "./Switch.module.css";
import { useThemeParams } from "@vkruglikov/react-telegram-web-app";

interface SwitchProps {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
}

export const Switch: FC<SwitchProps> = (props) => {
  const { checked, disabled, onChange } = props;
  const [colorScheme] = useThemeParams();

  const switchStyles = [styles.switch];
  const sliderStyles = [styles.slider];

  if (colorScheme === "dark") {
    sliderStyles.push(styles.dark);
  }

  if (checked) {
    sliderStyles.push(styles.checked);
  }

  if (disabled) {
    switchStyles.push(styles.disabled);
  }

  const handleChange = () => {
    if (disabled) return;
    onChange();
  };

  return (
    <div className={switchStyles.join(" ")} onClick={handleChange}>
      <span className={sliderStyles.join(" ")}></span>
    </div>
  );
};
